import React, { useState, useEffect } from "react";

import { HasuraConSEO } from "../../../../components/hasuracon2021/HasuraConSEO";
import { RegisterCTA } from "../../../../components/hasuracon2021/Schedule/RegisterCTA";
import { inputOptions } from "../../../../components/hasuracon2021/Schedule/NavigationStrip";
import PresentationListItem from "../../../../components/hasuracon2021/homepage/PresentationListItem";
import TopBanner from "../../../../components/hasuracon2021/homepage/TopBanner";
import HeroOverviewPattern from "../../../../components/hasuracon2021/images/hero-overview-pattern.png";
import NavPillToggle from "../../../../components/hasuracon2021/homepage/NavPillToggle";

import Layout from "../../../../components/hasuracon2021/Layout";
import interestedSpeakerGraphic from "../../../../components/hasuracon2021/images/interested-speaker-graphic.png";

const canonicalUrl = "https://hasura.io/events/hasura-con-2021/talks/";

const HasuraCon = ({ location, pageContext }) => {
  const { presentations } = pageContext;
  const { state } = location;

  const [filter, setFilter] = useState((state && state?.filter) || "conference");

  useEffect(() => {
    if (state && state.filter) {
      setFilter(state.filter);
    }
  }, [state]);

  const talkList = presentations
    .sort((p1, p2) => {
      return p1.node.childMdx.frontmatter.position - p2.node.childMdx.frontmatter.position;
    })
    .filter(p => !p.node.name.includes("placeholder"))
    .filter(({ node }) => {
      const { type } = node.childMdx.frontmatter;

      if (filter === "workshop") {
        return type === "workshop";
      }

      if (filter === "topic") {
        return type === "topic";
      }

      return type !== "workshop" && type !== "topic";
    })
    .map((presentation, index) => {
      return (
        <PresentationListItem
          key={index}
          presentation={presentation}
          base="/events/hasura-con-2021/talks"
        />
      );
    });

  return (
    <Layout
      location={location}
      heroGraphic={() => (
        <img
          className="heroBanRightPattern"
          loading="lazy"
          src={HeroOverviewPattern}
          alt="Speakers Graphic"
        />
      )}
      sideBarGraphic={() => (
        <img className="leftInterested" src={interestedSpeakerGraphic} alt="Graphic" />
      )}
    >
      <HasuraConSEO canonicalLink={canonicalUrl} />
      <TopBanner location={location} title="Talks" variant="simple" footer={() => <RegisterCTA />}>
        HasuraCon'21 is packed with 2 days of talks & workshops. The talks will feature Hasura
        product announcements, users stories, technical and deep-dives. The workshops will teach you
        how to get the most out of Hasura.
      </TopBanner>
      <NavPillToggle handleChange={setFilter} options={inputOptions} filter={filter} />
      <div className="presentationListWrapper">{talkList}</div>
    </Layout>
  );
};

export default HasuraCon;
